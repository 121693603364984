export const educationPieChart = {
  type: "doughnut",

  data: {
    labels: ["Completed", "Pending"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3BD761", "#DC5656"],
      },
    ],
  },
  options: {
    aspectRatio: 2.8,
    radius: "100%",
    responsive: true,
    cutoutPercentage: 90,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  },
};

export default educationPieChart;
