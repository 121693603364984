<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="assgn-learng button-reg">
      <b-button pill class="primar-btn lr-btn" size="lg" @click="assignLEarning()">
        Assign / Edit Learnings
      </b-button>
    </div>
    <div class="main-con d-flex">
      <div class="tm-con">
        <div class="con-header tm-hd-con">Team Members</div>
        <div v-for="teamMember in teamMembers" :key="teamMember.userId" class="tm-name"
          :class="{ 'selecte-tm': selectedMember == teamMember.userId }" @click="teamMemberSelected(teamMember)">
          <span>{{ teamMember.userName }}</span>
        </div>
      </div>
      <div class="ed-con">
        <div class="tm-lg-pd pdt-2 d-flex">
          <div class="width-50">
            <div class="con-header">
              PO Learning Plan - {{ selectedMemberName }}
            </div>
            <div class="table-header d-flex" style="padding: 0px">
              <div style="display: flex; padding: 0px">
                <div class="in-action card-tab-act" @click="getStatusWiseData(1)" :class="{
                  'primary-color': activeTab == 1,
                }">
                  <div :class="{
                    'primary-color': activeTab == 1,
                  }">
                    In Progress
                  </div>
                  <hr class="active-tab-hr" v-if="activeTab == 1" />
                </div>
                <div class="in-action card-tab-act" @click="getStatusWiseData(2)" :class="{
                  'primary-color': activeTab == 2,
                }">
                  <div :class="{
                    'primary-color': activeTab == 2,
                  }">
                    Completed
                  </div>
                  <hr class="active-tab-hr" v-if="activeTab == 2" />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right width-50 fill-con">
            <div class="search-box">
              <b-input class="search-box-input" id="searchInput" v-model="search" placeholder="Search Course"
                style="padding-right: 30px" @keyup="searchCourse()">
              </b-input>
              <span>
                <b-icon v-if="search.length > 0" @click="cancelSearch()" icon="X" class="icon-class"
                  aria-hidden="true"></b-icon>
                <b-icon v-else icon="search" class="icon-class close-icon" aria-hidden="true"></b-icon>
              </span>
            </div>

          </div>
        </div>
        <hr class="active-tab-divder" />
        <div class="row edu-detls">
          <div class="col-4 box-st" v-for="(i, index) in courseNo" :key="i.id" :id="i.id">
            <div style="padding-bottom: 10px">
              <div>

                <div class="col-md-12" style="padding: 0; padding-top: 10px">
                  <label class="img-label">
                    {{ i.componentId.split("_").pop() }}
                  </label>
                  <img alt="..." src="./../../assets/background_education.png" style="width: 100%" />
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12" style="padding-left: 0px; padding-top: 10px">
                  <span class="title-class">{{ i.title }}</span>
                </div>
                <div class="d-flex dtl-con">
                  <div class="wd-70" v-if="activeTab == 1">
                    <span class="du-on" v-if="i.requiredDate">Due On :</span>
                    <span class="du-on-date" v-if="i.requiredDate">
                      {{ i.requiredDate | dateFormater }}</span>
                  </div>
                  <div class="wd-70" v-if="activeTab == 2">
                    <span class="cmpltd" v-if="i.completionDate">Completed: {{
                      i.completionDate | dateFormater
                    }}</span><span class="cmpltd" v-else> NA</span>
                  </div>
                  <div class="wd-30 text-right d-cen dtl-btn" v-if="activeTab == 1"
                    @click="(detail_id = i.id), messageboxProp(index)">
                    <b-button v-b-modal.Messagebox class="primar-btn discard-btn ft-detail detail-btn"
                      style="padding-top: 0px; padding-right: 0px" size="lg">
                      Details
                    </b-button>
                    <b-icon class="detial-icon ft-detail-icon" icon="chevron-right"></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="courseNo.length == 0 && requestSendCourse" class="no-data">
          <div><img alt="no data" src="../../assets/No_Data.svg" /></div>
          <span class="ed-date">No Records Found</span>
        </div>
      </div>
      <div class="pr-con m-con">
        <div class="con-header tx-cen">{{ selectedMemberName }} Learnings</div>
        <div class="pie-chart-con" v-if="pendingCount > 0 || completedCount > 0">
          <EducationPieChart :pieData="pieData" />
        </div>
        <div class="bar-slice-info">
          <div class="d-flex bar-lables">
            <div class="completed-dot"></div>
            <div class="pie-details slice-name-cp">
              Completed:
              <span class="pie-details-num slice-count">{{
                completedCount
              }}</span>
            </div>
          </div>
        </div>
        <div class="bar-slice-info">
          <div class="d-flex bar-lables">
            <div class="completed-dot due-dot"></div>
            <div class="pie-details slice-name-due">
              Pending:
              <span class="pie-details-num slice-count">{{
                pendingCount
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
      <ActionItemFilter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
        :applyFilter="applyFilter" :resetFilter="resetFilter" :changedFilter="changedFilter"
        :filterSelected="filterSelected" :onSelect="() => { }" :onRemove="() => { }" :filterHeading="filterHeading" />
    </b-modal>
    <Messagebox class="alert" :dataObj="popUpObj" />
  </div>
</template>
<script>
import Loader from "../../components/common/Loader.vue";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import EducationPieChart from "../Dashboard/EducationPieChart.vue";
import Messagebox from "./messagebox.vue";
import ActionItemFilter from "../../components/Dashboard/ActionItemFilter.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "@/common/constants/api";
import Role from "../../common/constants/roles";
export default {
  name: "MyTeamLearning",
  data() {
    return {
      roles: Role,
      showLoader: false,
      selectedMember: 2,
      selectedMemberName: "",
      teamMembers: [],
      activeTab: 1,
      courseNo: [],
      searchbtn: 0,
      search: "",
      popUpObj: {
        id: "",
        imgScr: "",
        title: "",
        description: "",
        enrolled: "",
      },
      detail_id: "",
      noOfFilters: 2,
      pillarCode: ["a", "b"],
      ownerName: ["c", "d"],
      filterArray: [],
      filterSelected: [],
      filterHeading: "Refine By",
      pieData: [],
      pendingRecords: [],
      completedRecords: [],
      pendingCount: 0,
      completedCount: 0,
      requestSendCourse: false,
    };
  },
  components: {
    Breadcrumb,
    Loader,
    EducationPieChart,
    Messagebox,
    ActionItemFilter,
  },
  created() {
    if (
      this.$oidc.userProfile.roles.includes(this.roles.USER) &&
      this.$oidc.userProfile.roles.length == 1
    ) {
      this.$router.push("/");
      return;
    }
    this.$store.dispatch("selectedMenu", {
      main: 7,
    });
    this.$store.dispatch("breadcrumb", {
      title: "My Team's PO Learning",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Education", primary: false },
      ],
    });
    this.filterArray = [
      {
        labelName: "Pillar",
        arrayValue: this.pillarCode,
        id: "Pillar",
      },
      {
        labelName: "Owner Name",
        arrayValue: this.ownerName,
        id: "Owner",
      },
    ];
    this.getTeamMembers();
  },
  methods: {
    getTeamMembers() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETMDETAILSFORLU, "get").then((data) => {
        this.requestSendCourse = true;
        if (data.success) {
          this.teamMembers = data.data;
          if (this.teamMembers.length > 0) {
            this.getPendingAssignedLearningsByEmail(this.teamMembers[0].email);
            this.getCompletedAssignedLearningsByEmail(
              this.teamMembers[0].email
            );
            this.selectedMember = this.teamMembers[0].userId;
            this.selectedMemberName = this.teamMembers[0].userName;
            this.userProgress(this.teamMembers[0].email);
          } else {
            this.showLoader = false;
          }
        } else {
          // TODO document why this block is empty

        }
      });
    },
    teamMemberSelected(teamMember) {
      if (this.selectedMember == teamMember.userId) return;
      this.selectedMember = teamMember.userId;
      this.selectedMemberName = teamMember.userName;
      this.activeTab = 1;
      this.userProgress(teamMember.email);
      this.getPendingAssignedLearningsByEmail(teamMember.email);
      this.getCompletedAssignedLearningsByEmail(teamMember.email);
    },
    getStatusWiseData(status) {
      if (this.activeTab == status) return;
      this.activeTab = status;
      if (this.activeTab == 1) this.courseNo = [...this.pendingRecords];
      else this.courseNo = [...this.completedRecords];
    },
    cancelSearch() {
      this.search = "";
      if (this.activeTab == 1) this.courseNo = [...this.pendingRecords];
      else this.courseNo = [...this.completedRecords];
    },
    messageboxProp(key) {
      // this.$refs['Messagebox'].show()
      let desData = this.courseNo[key];
      desData["imgScr"] = desData["imageUrl"];
      this.popUpObj = desData;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() { },
    resetFilter() { },
    changedFilter() { },
    assignLEarning() {
      this.$router.push("/AssignLearning");
    },
    searchCourse() {
      let course = [];
      if (this.activeTab == 1)
        course = this.pendingRecords.filter((el) =>
          el.title.toLowerCase().includes(this.search.toLowerCase())
        );
      else
        course = this.completedRecords.filter((el) =>
          el.title.toLowerCase().includes(this.search.toLowerCase())
        );
      this.courseNo = course;
    },
    userProgress(email) {
      this.showLoader = true;
      ApiCalls.httpCallDetails(
        Api.USERLEARNINGPROGRESS + "?Email=" + email,
        "get"
      ).then((data) => {
        if (data.success) {
          this.completedCount = data.data.result.completedCount;
          this.pendingCount = data.data.result.pendingCount;
          this.pieData = [this.completedCount, this.pendingCount];
        } else {
          this.showLoader = false;
        }
      });
    },
    getPendingAssignedLearningsByEmail(email) {
      ApiCalls.httpCallDetails(
        Api.GETASSIGNEDLEARNGSBYEMAIL +
        2000 +
        "/" +
        "?Email=" +
        email +
        "&Type=pending",
        "get"
      ).then((data) => {
        this.showLoader = false;
        this.requestSendCourse = true;
        if (data.success) {
          this.courseNo = data.data.result;
          this.pendingRecords = data.data.result;
          this.pendingRecords.forEach((element) => {
            element.shortName = "" + element.componentId.split("_").pop() + "";
            element.title =
              element.title + " (" + element.componentId.split("_").pop() + ")";
            if (element.requiredDate)
              element.requiredDate = new Date(element.requiredDate);
          });
        }
      });
    },
    getCompletedAssignedLearningsByEmail(email) {
      ApiCalls.httpCallDetails(
        Api.GETASSIGNEDLEARNGSBYEMAIL +
        2000 +
        "/" +
        "?Email=" +
        email +
        "&Type=completed",
        "get"
      ).then((data) => {
        if (data.success) {
          this.completedRecords = data.data.result;
          this.completedRecords.forEach((element) => {
            element.title =
              element.title + " (" + element.componentId.split("_").pop() + ")";
            if (element.completionDate)
              element.completionDate = new Date(element.completionDate);
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.img-label {
  color: #3562a2;
  font-size: 3rem;
  font-family: "BarlowM", sans-serif;
  position: absolute;
  padding: 12% 0;
  text-align: center;
  width: 100%;
}

.m-con {
  padding: 1rem;
  padding-top: 2rem;
}

.tm-con {
  width: 20%;
  background: #f0f6ff;
  border-top-left-radius: 5px;
  overflow: auto;
}

.tm-hd-con {
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
}

.ed-con {
  width: 65%;
  border-right: 1px solid #e5e4e4;
}

.pr-con {
  width: 15%;
}

.con-header {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
}

.assgn-learng {
  position: absolute;
  top: 9rem;
  right: 4.5rem;
}

.lr-btn:focus {
  background-color: #274ba7;
  border-color: #274ba7;
}

.main-con {
  padding: 0;
  min-height: 34rem;
}

.tm-name {
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
  padding: 1rem;
  cursor: pointer;
}

.selecte-tm {
  background: #274ba7;
  color: #fff;
}

.active-tab-hr {
  border-top: 3px #234372 solid;
  margin-top: 1rem;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.act-tab-dr {
  margin-top: -2rem !important;
}

.in-action {
  margin-right: 1.688em;
}

.action-table tbody {
  font-size: 0.875em;
}

.table-header {
  margin-top: 1rem;
}

.active-tab-divder {
  margin-top: -1.2rem;
}

.tm-lg-pd {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pdt-2 {
  padding-top: 2rem;
}

.title-class {
  color: #000000;
  font-size: 1.1666666666666667rem;
  line-height: 1.5rem;
  font-family: "BarlowM", sans-serif;
}

.box-st {
  margin-left: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #cecbcb;
  border-radius: 5%;
}

.edu-detls {
  padding-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  /* height: 83vh; */
  overflow: auto;
}

.edu-detls .col-4 {
  flex: 0 0 33.333333%;
  max-width: 29.333333%;
}

.wd-70 {
  width: 70%;
}

.wd-30 {
  width: 30%;
}

.detial-icon {
  font-size: 9px;
  margin-left: 2px;
  margin-top: -0.5rem;
}

/* 
.ft-detail-icon {
  color: #274ba7 !important;
} */
.ft-detail-icon {
  color: #274ba7 !important;
  /* font-size: 1.1666666666666667rem !important; */
  font-family: "BarlowM", sans-serif !important;
}

.detail-btn {
  color: #274ba7 !important;
  font-size: 1.166rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.dtl-con {
  margin-top: 2rem;
}

.d-cen {
  display: flex;
  align-items: center;
}

.du-on {
  color: #dc5656 !important;
  font-size: 1rem !important;
  font-family: "BarlowSb", sans-serif !important;
}

.du-on-date {
  font-size: 1 !important;
  font-family: "BarlowM", sans-serif !important;
}

.cmpltd {
  font-size: 0.9166rem !important;
  font-family: "BarlowR", sans-serif !important;
}

.prg-timeln {
  font-size: 1rem !important;
  font-family: "BarlowR", sans-serif !important;
  text-align: center;
}

.pie-chart-con {
  width: 300px;
  margin-left: -6rem;
  margin-top: 2rem;
}

.pie-chart-con .chart__label {
  opacity: 0 !important;
}

.pie-chart-con .chart__line {
  opacity: 0 !important;
}

.bar-lables {
  align-items: center;
  /* justify-content: center; */
  margin-left: 15%;
}

.completed-dot {
  background-color: #3bd761;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.bar-slice-info {
  /* text-align: center; */
  position: relative;
  margin-top: 1rem;
}

.slice-name-cp {
  color: #3bd761;
}

.slice-count {
  color: #606060;
}

.slice-name-due {
  color: #dc5656;
}

.slice-name-td {
  color: #e7dd09;
}

.due-dot {
  background-color: #dc5656;
}

.pie-details-num {
  font-size: 1.5rem;
  font-family: "BarlowM", sans-serif;
  /* margin-left: 30%; */
}

.pie-details {
  font-size: 1.416rem;
  font-family: "BarlowM", sans-serif;
}

.tx-cen {
  text-align: center;
}

.search-box {
  display: flex;
  text-align: right;
  height: 30px;
}

.search-box-input {
  height: 40px;
  border: 1px solid #313131;
  font-family: "BarlowR", sans-serif;
  line-height: 1.25rem;
}

.acti-item-filter {
  /* width: 68%; */

  right: 10px;
  top: 35px !important;
}

.funnel-con {
  font-size: 16px;
  margin-right: 1rem;
  margin-top: 0.3rem;
}

.discard-btn1 {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn {
  /* color: #274ba7; */
  background: transparent;
  border: transparent;
  /* font-family: "BarlowM";
  font-size: 1.166rem;
  margin-right: 2rem; */
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.assign-course {
  padding-top: 20px;
  padding-left: 20px;
  flex-direction: column;
  /* padding-right: 20px; */
  /* height:450px; */
  padding-bottom: 20px;
  overflow: auto;
  /* display: flex;
  justify-content: center; */
}



.icon-class {
  margin-top: 10px;
  height: 20px;
  width: 18px;
  margin-left: -7rem;
  padding-right: 30px;
  padding-top: 2px;
  color: grey;
}

.close-icon {
  font-size: 2rem;
}

.search-course-tl {
  color: #313131;
  font-family: "BarlowM", sans-serif;
  font-size: 1.6666666666666667rem;
  line-height: 1.75rem;
  padding-left: 15px;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.fill-con {
  display: flex;
  flex-direction: row-reverse;
}

.dtl-btn {
  cursor: pointer;
}
</style>