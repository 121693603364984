<template>
  <div>
    <canvas id="education-pie-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import educationPieChart from './data/education-pie-chart'
export default {
  name: 'EducationPieChart',
  props: ['pieData'],
  data() {
    return {
      educationPieChart: educationPieChart
    }
  },
  mounted() {
    this.educationPieChart.data.datasets[0].data = this.pieData;
    const ctx = document.getElementById('education-pie-chart'); Chart.register(ChartJsPluginDataLabels);
    let chart = new Chart(ctx, this.educationPieChart);
    console.log(chart)
  }
}
</script>